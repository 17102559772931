<template>
  <v-row>
    <!-- page header -->
    <v-col cols="12">
      <v-toolbar flat rounded :outlined="$vuetify.theme.dark">
        <v-toolbar-title class="display-1 font-weight-medium">
          {{ pageHeaderTitle }}</v-toolbar-title
        >
        <v-spacer />
        <v-toolbar-items>
          <!-- actions -->
          <v-overflow-btn
            style="width: 400px"
            label="Actions"
            filled
            dense
            :items="actions"
            hint="Save, Update, etc."
            persistent-hint
            v-model="action"
            @input="onAction($event)"
          ></v-overflow-btn>
          <!-- actions -->
        </v-toolbar-items>
      </v-toolbar>
      <v-divider v-if="!$vuetify.theme.dark" />
    </v-col>
    <!-- page header -->
    <v-col cols="12">
      <v-card outlined>
        <v-card-text class="text--primary">
          <v-form ref="form" @submit.prevent="">
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  label="Package Category Title *"
                  v-model.trim="item.title"
                  hint="Capitalize first letter in every word"
                  persistent-hint
                  @blur="capitalizeFirstLetterEachWord"
                  required
                  :rules="[(v) => !!v || 'Field Required']"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-col>
    <!-- confirm item delete dialog -->
    <v-dialog
      v-model="dialogs.confirmDelete"
      width="500"
      :overlay-opacity="0.75"
    >
      <v-card outlined>
        <v-card-text
          class="items-center mt-6 red--text text--darken-1 headline font-weight-medium d-flex"
        >
          Warning: Permanent Action
        </v-card-text>
        <v-card-text class="text--primary body-1">
          <p>
            Deleting this package category will cause website errors if any
            packages are currently connected to this package category.
          </p>
          <p>
            <strong>
              Please ensure that no packages are using this package category
              before you delete it.
            </strong>
          </p>
          <p>
            If you have any questions, please contact the IT department for
            assistance.
          </p>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            depressed
            @click="dialogs.confirmDelete = false"
            >Cancel</v-btn
          >
          <v-spacer />
          <v-btn color="error" depressed @click="onDeleteItem"
            >Delete Item</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- confirm item delete dialog -->
  </v-row>
</template>

<script>
import { mainApp } from "@/firebase";
export default {
  mounted() {
    if (this.$route.params.id == "new") {
      this.pageHeaderTitle = "Add New Package Category";
    } else {
      this.getItem(this.$route.params.id);
    }
  },
  data: () => ({
    action: false,
    pageHeaderTitle: "",
    item: {
      title: "",
    },
    dialogs: {
      confirmDelete: false,
    },
  }),
  methods: {
    onDeleteItem() {
      mainApp
        .firestore()
        .collection("packageCategories")
        .doc(this.item.id)
        .delete()
        .then(() => {
          this.$router.push("/packages/categories");
          this.handleSuccess("Package Category Deleted Successfully");
        })
        .catch((err) => {
          this.handleError(err.message);
          setTimeout(() => {
            this.action = false;
          }, 500);
        });
    },
    capitalizeFirstLetterEachWord() {
      if (this.item.title) {
        this.item.title = this.item.title.replace(
          /(^\w{1})|(\s+\w{1})/g,
          (letter) => letter.toUpperCase()
        );
      }
    },
    onAction(event) {
      if (this.$refs.form.validate()) {
        if (event === "saveNew") {
          mainApp
            .firestore()
            .collection("packageCategories")
            .add(this.item)
            .then(() => {
              this.handleSuccess("Package Category Added Successfully");
              this.$router.push("/packages/categories");
            })
            .catch((err) => {
              this.handleError(err.message);
              setTimeout(() => {
                this.action = false;
              }, 500);
            });
        }
        if (event === "updateExisting") {
          mainApp
            .firestore()
            .collection("packageCategories")
            .doc(this.item.id)
            .update({
              title: this.item.title,
            })
            .then(() => {
              this.handleSuccess("Package Category Updated Successfully");
              this.$router.push("/packages/categories");
            })
            .catch((err) => {
              this.handleError(err.message);
              setTimeout(() => {
                this.action = false;
              }, 500);
            });
        }
        if (event === "delete") {
          this.dialogs.confirmDelete = true;
        }
      } else {
        // form  error
        this.handleError("Please Check Form for Errors");
        setTimeout(() => {
          this.action = false;
        }, 500);
      }
    },
    getItem(id) {
      this.$store.state.loading = true;
      // binding item
      this.$bind(
        "item",
        mainApp.firestore().collection("packageCategories").doc(id)
      )
        .then((item) => {
          this.item = item;
          // setting page header title
          this.pageHeaderTitle = `Edit Package Category ${item.title}`;
          //success
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.handleError(err.message);
        });
    },
  },
  computed: {
    actions() {
      if (this.$route.params.id == "new") {
        return [{ text: "Save New Package Category", value: "saveNew" }];
      } else {
        return [
          { text: "Update Package Category", value: "updateExisting" },
          { text: "Delete Package Category", value: "delete" },
        ];
      }
    },
  },
};
</script>

 